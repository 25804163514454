import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HeaderLeftTitleRightImage from "@notus-pro/react/Heading2Badge"

const props = {
  badge: { color: "indigo", children: "New Digs!" },
  title: "Come play at our new courts!",
  description:
    "The Las Flores Community Center has brand new courts with permanent nets making it one of the nicest places to play! ",
  alignment: "center",
}

export default function NewCourts() {
  return (
    <div style={{maxWidth: "1200px", display: "block", marginLeft: "auto", marginRight: "auto"}}>
      <br />
      <br />
      <HeaderLeftTitleRightImage {...props} />
      <br />
      <Carousel dynamicHeight="true" infiniteLoop="true" showIndicators="false">
          <div>
              <img src="newCourt8.jpg" />
          </div>
          <div>
              <img src="newCourt2.jpg" />
          </div>
          <div>
              <img src="newCourt12.jpg" />
          </div>
          <div>
              <img src="newCourt11.jpg" />
          </div>
          <div>
              <img src="newCourt20.jpg" />
          </div>
      </Carousel>
    </div>
  );
}
