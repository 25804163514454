import React from "react"

// @notus-pro/react
import FooterLarge from "@notus-pro/react/FooterLarge"

const props = {
  title: "Let's keep in touch!",
  description:
    "Find us on Facebook!",
  copyright: "Copyright © Napa Valley Pickleball 2022",
  socials: [
    { icon: "facebook", link: "https://www.facebook.com/groups/napavalleypickleball/" },
  ],
  links: [
    {
      name: "Useful Links",
      items: [
        {
          href: "https://usapickleball.org/what-is-pickleball/how-to-play/",
          target: "_blank",
          children: "How to Play",
        },
        {
          href: "https://usapickleball.org/",
          target: "_blank",
          children: "USA Pickleball",
        },
        {
          href: "https://www.pickleballcentral.com/Default.asp",
          target: "_blank",
          children: "Pickleball Central",
        },
      ],
    },
  ],
}

export default function Footer() {
  return (
    <>
      <FooterLarge {...props} />
    </>
  )
}
