import logo from './logo.svg';
import Header from './Header.js';
import Footer from './Footer.js';
import About from './About.js';
import People from './People.js';
import Locations from './Locations.js';
import Contact from './Contact.js';
import Merch from './Merch.js';
import NewCourts from './NewCourts.js';
import './App.css';

export default function App(){
  return (
    <div className="App">
      <Header bgImage="./headerBG.jpg" skewColor="white" title="" description="Napa Valley Pickleball is an independent group that aims to introduce and promote the sport of pickleball in the city of Napa" />
      <div style={{maxWidth: "85%", marginLeft: "auto", marginRight: "auto"}}>
        <About />
        <NewCourts />
        <Locations />
        <Merch />
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}
