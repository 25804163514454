import React from "react"

// @notus-pro/react
import Blogs1 from "./Blogs1.js"
import HeaderLeftTitleRightImage from "@notus-pro/react/Heading2Badge"

const props = {
  heading3: {
    color: "emerald",
    subtitle: "Locations",
    title: "Where we play",
    description:
      "There are a variety of places to play pickleball in the Napa Valley!",
    alignment: "center",
  },
  cards: [
  ],
}
const props2 = {
  heading3: {
  },
  cards: [
    {
      image: "./lasFlores.png",
      subtitle: "Las Flores Park",
      title: "4300 Linda Vista Avenue Napa, CA 94559",
      size: "lg",
      link: { href: "https://goo.gl/maps/9XEpi16tjEYF9NeK9" },
    },
    {
      image: "./yountville.png",
      subtitle: "Vineyard Park Outdoor Courts",
      title: "1800 Oak Circle Yountville, CA 94599",
      size: "lg",
      link: { href: "https://g.page/vineyard-park-yountville?share" },
    },
  ],
}
const props3 = {
  description:
    "Courts are always open to play but the best times to come out are between 8am-noon every day!",
  alignment: "center",
}

export default function Locations() {
  return (
    <>
        <Blogs1 {...props} />
        <div style={{marginTop: "-150px"}}>
          <Blogs1 {...props2} />
        </div>
        <HeaderLeftTitleRightImage {...props3} />
    </>
  )
}
