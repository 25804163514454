import React from "react"

// @notus-pro/react
import Hero2 from "@notus-pro/react/Hero2"

const props = {
  heading2badge: {
    title: "Who We Are",
    description:
      "The Napa Valley Pickleball group envisions a friendly, fun, and inclusive place where people living in or visiting Napa Valley can enjoy the game of pickleball." ,
  },
  image:
    "./newCourt3.jpg",
  infoArea: {
    icon: "fas fa-heart",
    iconColor: "blueGray",
    title: "Our Mission",
    list: [
      { icon: "fas fa-dot-circle", text: "To promote pickleball throughout the Napa Valley; both recreationally and competitively for players of all ages and abilities" },
      { icon: "fas fa-dot-circle", text: "To cultivate an environment that promotes social interaction, skill development, good sportsmanship, and fun" },
      { icon: "fas fa-dot-circle", text: "To enhance our facilities, programs, and activites" },
    ],
  },
}

export default function About() {
  return (
    <>
      <Hero2 {...props} />
    </>
  )
}
