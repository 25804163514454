import React from "react"
import { Link } from 'react-router-dom';
// @notus-pro/react
import Hero9 from "@notus-pro/react/Hero9"
import Button from "@notus-pro/react/Button"
let text = "Our member T Santora has provided us with a source for Napa Valley Pickleball t-shirts and accessories! T is an accomplished artist (and pickleball enthusiast) who splits his time between Napa and Palm Springs, CA. He's created a whole collection of pickleball designs which are sold online through his Dinky Pickleball Collection on TeePublic!";


// texts as props
const product1 = {
  heading3badge: {
    badge: { color: "pink", children: "Have you seen us?" },
    title: "We've got merch!",
    description: text,
    alignment: "center",
  },
  cards: [
    {
      image: "./crusherT.jpg",
      icon: "fas fa-calendar-check",
      color: "red",
      text: "Wearing",
      description:
        "Our classic logo on a comfy T-shirt! What more could you want? You know, other than to play pickleball every day.",
      title: "NVP Crusher Essential",
      link: { href: "https://www.teepublic.com/t-shirt/28463652-napa-valley-pickleball-2-sided?store_id=1225064" },
    },
    {
      image: "./caliLoveT.jpg",
      icon: "fas fa-calendar-check",
      color: "red",
      text: "Fashion",
      description:
        "Someone already has this license plate but that doesn't mean you have to miss out on it!",
      title: "I❤️PKLBL",
      link: { href: "https://www.teepublic.com/t-shirt/28463803-california-dreamin-pickleball-license?store_id=1225064" },
    },
    {
      image: "./caliLoveT2.jpg",
      icon: "fas fa-calendar-check",
      color: "red",
      text: "Fashion",
      description:
        "Tupac knew how to party but with this shirt, everyone will know you love to play!",
      title: "California Love",
      link: { href: "https://www.teepublic.com/t-shirt/28463799-california-loves-pickleball?store_id=1225064" },
    },
  ],
}

export default function Merch() {
  return (
    <>
      <Hero9 {...product1} />
      <Button target="_blank" href="https://www.teepublic.com/user/t-santora/albums/202961-dinkys-pickleball-collection">Shop the entire collection!</Button>
    </>
  )
}
